import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const About = () => (
  <Layout pageInfo={{
    pageName: "About" +
      "",
  }}>
    <SEO title="About page"
         description="About KnowEthereum and its author"
         keywords={[`Ethereum`, `About KnowEthereum`]}/>
    <Container fluid className="px-5 py-5 about-container">
      <Row noGutters>
        <Col className="col-md-10 offset-md-1">
          <Container fluid>
            <Row noGutters>
              <h1 className="hero-title">
                About KnowEthereum
                <hr className="hero-separator"/>
              </h1>
            </Row>
            <Row noGutters>
              <p className="mt-4 hero-text about-text">
                KnowEthereum was built with the intention of aggregating the best Ethereum resources into one single
                place. Resources are divided into different categories, you can view them, search for a category or an
                individual item, add the ones that you like to your favorites and so on. Categories should help
                different kinds of persons (visual, auditory, and kinesthetic) find their preferred kind of resources.

                Resources should appeal to people of different interests. They include topics like learning,
                programming, trading, HODLing, and much more.

                The site has just recently launched so please pardon if some functionality may not work as intended,
                expect to see more updates in the future. Also, you may miss some of the well-known resources, so don't
                hesitate to use the suggest button and ask for it to be included. Have a nice day and keep your interest
                in Ethereum, this will pay you off in the future!
              </p>
            </Row>
            <Row noGutters>
              <h2 className="hero-title mt-4">
                About the creator
                <hr className="hero-separator"/>
              </h2>
            </Row>
            <Row noGutters>
              <p className="mt-4 hero-text about-text">
                I am {` `}
                <a href="https://etherscan.io/address/0x09cf79bdf8f68739979c8c825c103a7538bd4f4b"
                   target='_blank' rel='noopener noreferrer'
                   aria-label="etherscan address"
                   className="text-dark">
                  EtherPaul
                </a>
                , the creator of this site. I am a Software developer with a solid
                experience in Solidity, web3, and other Ethereum related technologies. I have been interested in this
                space since August 2017. I am passionate about the Ethereum and the Blockchain ecosystem as a whole.
                During the years of following and learning about this space, I have found it hard to select valuable
                resources as everything changes so fast. Thus, I have decided to create this site where I put my
                favorite resources and divide them into categories and share it with you for the purpose of making each
                other lives easier as well as educating and spreading the knowledge about this awesome technology. The
                choice of resources is at my own discretion. I
                try to include everything that is high quality and worth knowing for every Ethereum enthusiast. I hope
                that you will appreciate my work, share it with your colleagues, and help me improve the site.
                Together we can push Ethereum to the mainstream!
              </p>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default About
